/* eslint-disable no-unreachable */
import Button from '@components/Button/index';
import CarouselV2 from '@components/CarouselV2/index';
import AccordionV2 from '@components/Common/AccordionV2/index';
import FAQsV2 from '@components/Common/FAQsV2/index';
import FloatingBar from '@components/Common/FloatingBar/index';
import SEO from '@components/Common/SEO/index';
import DownloadPlacementStats from '@components/DownloadPlacementStats';
import FellowshipCarousel from '@components/Fellowship/Carousel/index';
import VideoPopup from '@components/Fellowship/VideoPopup/index';
import RedirectToRegionalSite from '@components/RedirectToRegionalSite/index';
import ScrollCTA from '@components/ScrollCTA/index';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplyNowCTA from '@components/Common/ApplyNowCTA/index';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { getExperimentAndVariant } from '../../analytics/gtm';
import countries from '../../../utils/i18n-countries';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import db from '../../data/db.json';
import './index.css';
import * as styles from './TrialDeveloperExperience.module.scss';
import { EAnchorIds } from '@src/constants/anchorLinks/index';
import { ESale, EScholarship } from '@src/constants/sale/index';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import SaleStrip from '@components/SaleStrip/index';
import { stripData } from '@components/Sale/data';
import { useDialog } from '@components/v5/Dialog/index';
// import TopStrip from '@components/PlacementReportTopStrip/index';
import Navigation from '@components/Navigation';
import FooterV2 from '@components/FooterV2/index';
import { EProgramIDs } from '@src/constants/data/programs/index';
import { useLocation } from '@reach/router';
import Loader from '@components/Loader/index';

// switch (getExperimentAndVariant().variant) {
//   case "1":
//     typeform = "https://crioforms.typeform.com/to/NpPpJabP/";
//     break;
//   case "2":
//     typeform = "https://crioforms.typeform.com/to/NpPpJabP/";
//     break;
//   default:
//     typeform = "https://crioforms.typeform.com/to/MxiMFFks/";
// }
export default function TrialDeveloperExperience(props) {
  const ref = useRef();

  const location = useLocation();

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  let [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      isMobile =
        window.screen.width <= 920 ? setIsMobile(true) : setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    if (window) {
      window.location.replace(
        '/software-development-fellowship-program/' + location?.search,
      );
    }
  }, []);

  // console.log('debug > ', props);

  const pageData = props.data.strapiPrograms;
  const sale = props.data.strapiSale.SaleID;
  const [email, setEmail] = useState('');
  const [applyNowHero, setApplyNowHero] = useState(false);
  const [video, setVideo] = useState('');
  const [openVideo, setOpenVideo] = useState(false);
  let [bannerClass, setBannerClass] = useState(`${styles.banner}`);
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    if (position > 500) {
      // console.log(scrollTop);
      setBannerClass(``);
    } else {
      setBannerClass(`${styles.banner}`);
    }
    // console.log(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const floatingBarData = [
    {
      title: 'Fully online',
      description: pageData.offeringHours + ' Hours',
    },
    { title: 'Scholarship', description: 'Assured after trial' },
    // {
    //   title: 'Next batch',
    //   description: format(new Date(pageData.ProgramDate), 'MMMM dd, yyyy'),
    // },
  ];

  const graduatesData = props.data.allStrapiGraduates.nodes.reduce(
    (result, obj) => {
      // eslint-disable-next-line no-unused-expressions
      obj.showInPlacementsSection &&
      obj.company &&
      obj.company.Logo &&
      obj.Photo
        ? result.push({
            name: obj.Name,
            companyLogo: obj.company.Logo,
            profileImage: obj.Photo,
          })
        : '';
      return result;
    },
    [],
  );

  const companiesData = props.data.allStrapiCompanies.nodes.reduce(
    (result, obj) => {
      result.push({
        id: obj.strapiId,
        name: obj.Name,
        url: obj.Logo.localFile,
      });
      return result;
    },
    [],
  );
  const allYoutubeThumbnail = props.data.allYoutubeThumbnail.nodes;

  const testimonialQuotesData =
    props.data.allStrapiTestimonialWithQuotes.nodes.reduce((result, obj) => {
      result.push({
        name: obj.graduate.Name,
        linkedin: obj.LinkedInLink,
        companyLogo: companiesData.find((val) => val.id == obj.graduate.company)
          ?.url,
        iFrame: obj.YoutubeVideoLink,
        quote: obj.Quote,
        thumbnail: allYoutubeThumbnail.find(
          (ele) => ele.name == String(obj.strapiId),
        ).childImageSharp,
      });
      return result;
    }, []);

  const typeform =
    getExperimentAndVariant().variant == 1
      ? pageData.ABTest.TypeformVariant1
      : pageData.typeform;

  const typeOfProgram =
    getExperimentAndVariant().variant == 1
      ? 'FREE_TRIAL_OFFLINE'
      : pageData.PrimaryProgramID;

  //images
  const HeroMobile = getImage(props.data.strapiPrograms.HeroMobile.localFile);
  const HeroDesktop = getImage(props.data.strapiPrograms.HeroDesktop.localFile);
  const FooterImage = getImage(props.data.strapiPrograms.FooterImage.localFile);

  const getSaleHeroImage = (sale, isMobile) => {
    if (isMobile) {
      switch (sale) {
        case ESale.DIWALI_SALE:
          return (
            <div
              className="relative mt-2"
              // style={{ marginTop: '-5.5rem' }}
            >
              <div className="absolute top-[18px] left-4 z-[2] w-40">
                <StaticImage
                  loading="eager"
                  src="../../images/diwali-sale/Badge-Free-Trial.png"
                  alt="Badge"
                  className="w-full"
                  placeholder="blurred"
                />
                {/* <Image
                  filename="diwali-sale/Badge-Free-Trial.png"
                  className="w-full"
                /> */}
              </div>
              <StaticImage
                loading="eager"
                src="../../images/copyPasteSale/Trial-Mobile.png"
                alt="Badge"
                className="w-full"
                placeholder="blurred"
              />
              {/* <Image filename="copyPasteSale/Trial-Mobile.png" /> */}
            </div>
          );

        case ESale.CYBER_SALE:
          return (
            <div
              className="relative mt-2"
              // style={{ marginTop: '-5.5rem' }}
            >
              <div className="absolute top-[18px] left-4 z-[2] w-40">
                <StaticImage
                  loading="eager"
                  src="../../images/cyber-sale/Badge-Free-Trial.png"
                  alt="Badge"
                  className="w-full"
                  placeholder="blurred"
                />
                {/* <Image
                  filename="diwali-sale/Badge-Free-Trial.png"
                  className="w-full"
                /> */}
              </div>
              <StaticImage
                loading="eager"
                src="../../images/copyPasteSale/Trial-Mobile.png"
                alt="Badge"
                className="w-full"
                placeholder="blurred"
              />
              {/* <Image filename="copyPasteSale/Trial-Mobile.png" /> */}
            </div>
          );

        case 'true':
          return (
            <div className="relative mt-2">
              <div className="absolute top-[26px] left-4 z-[2] w-36">
                <StaticImage
                  loading="eager"
                  src="../../images/v5/Sale/All.png"
                  alt="Badge"
                  width={300}
                  placeholder="blurred"
                />
              </div>
              <StaticImage
                loading="eager"
                src="../../images/copyPasteSale/Trial-Mobile.png"
                alt="Badge"
                className="w-full"
                placeholder="blurred"
              />
            </div>
          );

        default:
          return (
            <div className="relative">
              <div className="absolute top-12 left-4 z-[2] w-36">
                <StaticImage
                  loading="eager"
                  src="../../images/Badge-Free-Trial.png"
                  alt="Badge"
                  placeholder="blurred"
                  className="w-full"
                />
                {/* <Image filename="Badge-Free-Trial.png" className="w-full" /> */}
              </div>
              <GatsbyImage
                loading="eager"
                image={HeroMobile}
                alt={'Hero Image'}
              />
              {/* <Image
                filename={`strapi/${pageData.HeroMobile.url.split('/')[2]}`}
              /> */}
            </div>
          );
      }
    } else {
      switch (sale) {
        case ESale.DIWALI_SALE:
          return (
            <div className="relative mt-6">
              <div className="absolute top-[20%] right-[80px] z-[2] w-28 rotate-12">
                <StaticImage
                  loading="eager"
                  src="../../images/diwali-sale/Badge-Free-Trial.png"
                  alt="Badge"
                  placeholder="blurred"
                  className="w-full"
                />
                {/* <Image
                  filename="diwali-sale/Badge-Free-Trial.png"
                  className="w-full"
                /> */}
              </div>
              <GatsbyImage
                loading="eager"
                image={HeroDesktop}
                alt={'Hero Image'}
              />
              {/* <Image
                filename={`strapi/${pageData.HeroDesktop.url.split('/')[2]}`}
              /> */}
            </div>
          );

        case ESale.CYBER_SALE:
          return (
            <div className="relative mt-6">
              <div className="absolute top-[20%] right-[80px] z-[2] w-36 rotate-12">
                <StaticImage
                  loading="eager"
                  src="../../images/cyber-sale/Badge-Free-Trial.png"
                  alt="Badge"
                  placeholder="blurred"
                  className="w-full"
                />
                {/* <Image
                  filename="diwali-sale/Badge-Free-Trial.png"
                  className="w-full"
                /> */}
              </div>
              <GatsbyImage
                loading="eager"
                image={HeroDesktop}
                alt={'Hero Image'}
              />
              {/* <Image
                filename={`strapi/${pageData.HeroDesktop.url.split('/')[2]}`}
              /> */}
            </div>
          );

        case 'true':
          return (
            <div className="relative -mt-2">
              <div className="absolute top-[25%] right-[50px] z-[2] w-28 rotate-6">
                <StaticImage
                  loading="eager"
                  src="../../images/v5/Sale/All.png"
                  alt="Badge"
                  placeholder="blurred"
                  className="w-full"
                />
              </div>
              <GatsbyImage
                loading="eager"
                image={HeroDesktop}
                alt={'Hero Image'}
              />
            </div>
          );

        default:
          return (
            <div className="relative mt-6">
              <div className="absolute top-[30%] right-[80px] z-[2] w-28 rotate-12">
                {/* <Image filename="Badge-Free-Trial.png" className="w-full" /> */}
                <StaticImage
                  loading="eager"
                  src="../../images/Badge-Free-Trial.png"
                  alt="Badge"
                  placeholder="blurred"
                  className="w-full"
                />
              </div>
              {/* <Image
                filename={`strapi/${pageData.HeroDesktop.url.split('/')[2]}`}
              /> */}
              <GatsbyImage
                loading="eager"
                image={HeroDesktop}
                alt={'Hero Image'}
              />
            </div>
          );
      }
    }
  };
  const getSaleHeroSubCaption = (sale) => {
    switch (sale) {
      case ESale.DIWALI_SALE:
        return (
          <div className="hidden pb-4 text-copy-paste-sale-gray-900 md:block">
            <h6 className="text-xl font-medium leading-relaxed">
              Earn up to{' '}
              <span className="text-diwali-sale-purple-200 font-bold">
                ₹ 45,000 scholarship
              </span>
            </h6>
            <p className="text-xs">Limited Time Offer.</p>
          </div>
        );

      case ESale.CYBER_SALE:
        return (
          <div className="hidden pb-4 text-copy-paste-sale-gray-900 md:block">
            <h6 className="text-xl font-medium leading-relaxed">
              Earn up to{' '}
              <span className="text-diwali-sale-purple-200 font-bold">
                ₹ 45,000 scholarship
              </span>
            </h6>
            <p className="text-xs">Limited Time Offer.</p>
          </div>
        );

      case 'true':
        return (
          <div className="hidden pb-4 text-copy-paste-sale-gray-900 md:block">
            <h6 className="text-xl font-medium leading-relaxed">
              Earn up to{' '}
              <span className="font-bold text-sale-orange-300">
                ₹{EScholarship[EProgramIDs.FREE_TRIAL]} scholarship
              </span>
            </h6>
            <p className="text-xs">Limited Time Offer.</p>
          </div>
        );

      default:
        return '';
    }
  };

  const getSaleImageCaptionFlex = (sale) => {
    switch (sale) {
      case ESale.DIWALI_SALE:
        return (
          <section className="bg-diwali-sale-purple-900 text-diwali-sale-neutral-300">
            <div className="pt-10  pb-10 ">
              <div className="container">
                <div className="flex justify-center gap-x-2">
                  <div className="bg-diwali-sale-purple-700 text-diwali-sale-neutral-300 mx-4 inline-block rounded-md px-4 py-1 text-center font-bold md:hidden">
                    #LightUpYourPortfolio!
                  </div>
                  <div className="col-12 col-md-4 flex justify-center">
                    {' '}
                    <div className=" mb-8 px-16 md:mb-0 md:px-0">
                      <StaticImage
                        placeholder="blurred"
                        loading="eager"
                        src="../../images/diwali-sale/Motif.png"
                        alt="Diwali Motif"
                      />
                      {/* <Image filename={'diwali-sale/Motif.png'}></Image> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-8 text-center md:text-left">
                    <div
                      className="bg-diwali-sale-purple-700 text-diwali-sale-neutral-300 mb-4 hidden rounded-md px-4 py-1 font-bold md:block"
                      style={{ width: 'fit-content' }}
                    >
                      #LightUpYourPortfolio!
                    </div>
                    <h2
                      className={`${styles.customFont}  mb-8 px-4 font-normal leading-snug md:mb-4 md:px-0`}
                    >
                      Build Professional Software Projects to gain Real Work
                      Experience in Full-Stack and Backend Development.
                    </h2>
                    <p className="mb-4">
                      Earn up to{' '}
                      <u className="font-bold text-copy-paste-sale-yellow-100">
                        ₹ 45,000 scholarship
                      </u>
                      .
                      <br className="md:hidden" /> Limited Time Offer.
                    </p>
                    <div
                      className={` flex justify-center md:justify-start ${styles.button}`}
                    >
                      <ApplyNowCTA
                        program={pageData}
                        type={typeOfProgram}
                        meId={typeOfProgram}
                        innerText="Book Now"
                        link={typeform}
                        location="FREE_TRAIL_LEAD_CAPTURE_STRIP"
                        text="Book Your Free Trial"
                        width={'250px'}
                        className="shimmer-btn bg-crio-yellow-500 py-3  text-crio-green-900"
                        applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                        openEvent="FREE_ME_START_NOW_CLICKED"
                        closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                      ></ApplyNowCTA>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );

      case ESale.CYBER_SALE:
        return (
          <section className="bg-cyber-sale-green-900 text-cyber-sale-neutral-100">
            <div className="pt-10  pb-10 ">
              <div className="container">
                <div className="flex justify-center gap-x-2">
                  <div className="text-cyber-sale-lime-400 bg-cyber-sale-green-800  mx-4 inline-block rounded-md px-4 py-1 text-center font-bold md:hidden">
                    <span className="font-bold">#RelaunchYourDevCareer</span>
                  </div>
                  <div className="col-12 col-md-4 flex items-center justify-center">
                    {' '}
                    <div className=" mb-8 px-16 md:mb-0 md:px-0">
                      <StaticImage
                        placeholder="blurred"
                        loading="eager"
                        src="../../images/cyber-sale/HeroImage.png"
                        alt="Diwali Motif"
                      />
                      {/* <Image filename={'diwali-sale/Motif.png'}></Image> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-8 text-center md:text-left">
                    <div
                      className="text-cyber-sale-lime-400 bg-cyber-sale-green-800 mb-4 hidden rounded-md px-4 py-1 font-bold md:block"
                      style={{ width: 'fit-content' }}
                    >
                      <span className="font-bold">#RelaunchYourDevCareer</span>{' '}
                      <span className="text-cyber-sale-neutral-100 font-bold">
                        this Cyber Monday!
                      </span>
                    </div>
                    <h2
                      className={`${styles.customFont} text-cyber-sale-neutral-100 mb-8 px-4 text-xl font-normal leading-snug md:mb-4 md:px-0 md:text-2xl`}
                    >
                      Build Professional Software Projects to gain Real Work
                      Experience in Full-Stack and Backend Development.
                    </h2>
                    <p className="mb-4">
                      Earn up to{' '}
                      <u className="text-cyber-sale-lime-400 font-bold">
                        ₹ 45,000 scholarship
                      </u>
                      .
                      <br className="md:hidden" /> Limited Time Offer.
                    </p>
                    <div
                      className={` flex justify-center md:justify-start ${styles.button}`}
                    >
                      <ApplyNowCTA
                        program={pageData}
                        type={typeOfProgram}
                        meId={typeOfProgram}
                        innerText="Book Now"
                        link={typeform}
                        location="FREE_TRAIL_LEAD_CAPTURE_STRIP"
                        text="Book Your Free Trial"
                        width={'250px'}
                        className="shimmer-btn bg-crio-yellow-500 py-3  text-crio-green-900"
                        applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                        openEvent="FREE_ME_START_NOW_CLICKED"
                        closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                      ></ApplyNowCTA>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );

      case 'true':
        return (
          <SaleStrip data={stripData.FREE_TRIAL}>
            <ApplyNowCTA
              program={pageData}
              type={typeOfProgram}
              meId={typeOfProgram}
              innerText="Book Now"
              link={typeform}
              location="FREE_TRAIL_LEAD_CAPTURE_STRIP"
              text="Book Your Free Trial"
              width={'250px'}
              className="shimmer-btn bg-crio-yellow-500 py-3  text-crio-green-900"
              applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
              openEvent="FREE_ME_START_NOW_CLICKED"
              closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
            ></ApplyNowCTA>
          </SaleStrip>
        );

      default:
        return (
          <section className="bg-copy-paste-sale-green-900 text-white">
            <div className="pt-10  pb-10 ">
              <div className="container">
                <div className="row ">
                  <div className="col-12 col-md-4 flex justify-center">
                    {' '}
                    <div className=" mb-8 px-16 md:mb-0 md:px-0">
                      <StaticImage
                        loading="eager"
                        src="../../images/MiddleStripImage.png"
                        alt="Middle Strip"
                        placeholder="blurred"
                      />
                      {/* <Image filename={'MiddleStripImage.png'}></Image> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-8 flex flex-col justify-center text-center md:text-left">
                    <h2
                      className={`${styles.customFont}  mb-8 px-4 font-normal leading-snug text-crio-neutral-100 md:mb-4 md:px-0`}
                    >
                      Learn by building Full-Stack / Backend projects on your
                      own.
                    </h2>
                    <p className="mb-4 text-crio-neutral-100">
                      Earn up to{' '}
                      <u className="font-bold text-copy-paste-sale-yellow-100">
                        ₹ 45,000 scholarship
                      </u>
                      . <br className="md:hidden" />
                    </p>
                    <div
                      className={` flex justify-center md:justify-start ${styles.button}`}
                    >
                      <ApplyNowCTA
                        program={pageData}
                        type={typeOfProgram}
                        meId={typeOfProgram}
                        innerText="Book Now"
                        link={typeform}
                        location="FREE_TRAIL_LEAD_CAPTURE_STRIP"
                        text="Book Your Free Trial"
                        width={'250px'}
                        className="shimmer-btn bg-crio-yellow-500 py-3  text-crio-green-900"
                        applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                        openEvent="FREE_ME_START_NOW_CLICKED"
                        closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                      ></ApplyNowCTA>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
    }
  };

  let countryURL = null,
    countryFlag = false;
  if (countries.includes(state.country)) {
    let page = db.strapi_metadata.programs.find(
      (ele) =>
        ele.countryName.split('__')[1] == state.country &&
        ele.PrimaryProgramID == 'FULL_STACK',
    );
    if (page) {
      countryURL = '/' + page.slugUrl;
      countryFlag = true;
    }
  }

  const [openDialog, closeDialog] = useDialog();

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <Loader></Loader>
    </div>
  );

  if (countryFlag) {
    return <RedirectToRegionalSite url={countryURL}></RedirectToRegionalSite>;
  } else {
    return (
      <>
        <SEO
          title={pageData.SEO?.SEOTitle}
          description={pageData.SEO?.SEODescription}
        ></SEO>

        {/* <VideoPopup
          isOpen={openVideo}
          video={video}
          setOpen={(val) => setOpenVideo(val)}
        ></VideoPopup> */}
        <section className="bg-crio-neutral-100">
          <Navigation hideCTA={true} program={typeOfProgram} sale={sale} />
          {/* <div className="fixed-header">
            <Header scrolled={true}></Header>{' '}
          </div> */}
          <ScrollCTA
            startAt={600}
            link={typeform}
            program={pageData}
            type={typeOfProgram}
            meId={typeOfProgram}
            location="FLOATING_CTA"
            openEvent="FREE_ME_START_NOW_CLICKED"
            closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
          >
            <Button
              color="#05343A"
              className="shimmer-btn text-xl"
              style={{
                // background: '#FFC372',
                border: '1px solid #FFC372',
                width: 'calc(90vw - 10px)',
                background:
                  'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
              }}
              type={'primary'}
            >
              Book your free trial
              <FontAwesomeIcon
                icon={['fas', 'arrow-up']}
                className="ml-2 rotate-45"
                style={{ marginBottom: '2px' }}
              ></FontAwesomeIcon>
            </Button>
          </ScrollCTA>
          <section className="relative overflow-hidden  bg-crio-neutral-100 ">
            <div className="pt-20 md:hidden">
              {getSaleHeroImage(sale, true)}
            </div>
            <div className="row -mt-32 pb-36 md:-mt-0 md:pt-32 md:pb-20">
              <div className="col-12 col-md-7 z-10 flex flex-col items-center md:block ">
                <div className="flex flex-col px-3 pt-20 pb-5 text-center text-crio-green-700   md:block md:pt-5 md:pl-20 md:pr-10 md:text-left ">
                  <div
                    className={` my-3 flex cursor-pointer  justify-center md:hidden`}
                    // onClick={() => {
                    //   setVideo(pageData.VideoEmbedLink);
                    //   setOpenVideo(true);
                    // }}
                    onClick={() => {
                      openDialog({
                        children: (
                          <VideoPopup
                            video={pageData.VideoEmbedLink}
                            closeDialog={closeDialog}
                          ></VideoPopup>
                        ),
                      });
                    }}
                  >
                    <div
                      className={`flex  items-center justify-center bg-white text-crio-green-700 transition-all  `}
                      style={{
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'play']}
                        className="text-sm"
                      />{' '}
                    </div>
                  </div>
                  <h1
                    className={`${styles.heading} mb-2 text-4xl leading-normal`}
                  >
                    {pageData.HeroTitle}
                  </h1>
                  {getSaleHeroSubCaption(sale)}
                  <div className=" leading-loose text-black">
                    <ReactMarkdown>{pageData.HeroDescription}</ReactMarkdown>
                  </div>
                  <div
                    className={` my-3 hidden w-max cursor-pointer items-center md:flex ${styles.parent}`}
                    // onClick={() => {
                    //   setVideo(pageData.VideoEmbedLink);
                    //   setOpenVideo(true);
                    // }}
                    onClick={() => {
                      openDialog({
                        children: (
                          <VideoPopup
                            video={pageData.VideoEmbedLink}
                            closeDialog={closeDialog}
                          ></VideoPopup>
                        ),
                      });
                    }}
                  >
                    <div
                      className={`mr-3 flex  items-center  justify-center bg-crio-green-700 text-white transition-all ${styles.play}`}
                      style={{
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'play']}
                        className="m-4 text-sm"
                      />{' '}
                    </div>
                    <h6 className="font-semibold text-black">
                      Intro to Crio {pageData.ProgramName}
                    </h6>
                  </div>
                </div>
                <div
                  className={` ${styles.button} container mb-5 flex flex-col flex-wrap items-center pl-3 md:flex-row md:pl-20`}
                >
                  <div className={`flex justify-center  ${styles.button}`}>
                    <ApplyNowCTA
                      innerText="Book Now"
                      program={pageData}
                      type={typeOfProgram}
                      meId={typeOfProgram}
                      link={typeform}
                      location="PROGRAM_DETAIL_HERO"
                      text="Book Your Free Trial"
                      width={'250px'}
                      className="shimmer-btn mx-auto bg-crio-yellow-500  py-3 text-crio-green-900"
                      applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                      openEvent="FREE_ME_START_NOW_CLICKED"
                      closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                    ></ApplyNowCTA>
                  </div>
                </div>
              </div>{' '}
              <div className="col-12 col-md-5 z-0 hidden p-0 md:block">
                <div className="absolute -bottom-20">
                  {getSaleHeroImage(sale, false)}
                </div>
              </div>
            </div>
          </section>{' '}
          <div className="relative mt-20 md:mt-0">
            <div className="absolute -top-52  flex w-full justify-center  md:-top-12">
              <FloatingBar data={floatingBarData}></FloatingBar>
            </div>
          </div>
          <section className="bg-crio-neutral-75 ">
            <div className="pt-28 pb-10 ">
              <div className="container text-center">
                <FadeInWhenVisible
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <h2 className={`mb-5 text-crio-green-700 ${styles.heading}`}>
                    {pageData.HighlightsTitle}
                  </h2>
                </FadeInWhenVisible>
                <FadeInWhenVisible
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <div className="container">
                    <div className=" row  mb-10 leading-7">
                      <div className="col-6 col-md-3 flex p-2 md:flex-col">
                        <div className=" rounded-md bg-crio-neutral-200 p-4 text-left md:mr-3 md:bg-transparent md:p-0">
                          <div className="mb-4 mr-4 md:mr-0 ">
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/WhyCrio/One.png"
                              placeholder="blurred"
                              alt="Why Crio - True PBL"
                              className={styles.whyCrioImage}
                            />
                            {/* <Image
                              filename="home/WhyCrio/One.png"
                              className={styles.whyCrioImage}
                            ></Image> */}
                          </div>
                          <h5
                            className={`font-semibold ${styles.customFont} mb-3 text-crio-green-900`}
                          >
                            No videos or boring tutorials
                          </h5>

                          <p className="text-sm leading-loose">
                            Write code. Build projects. Learn hands-on.
                          </p>
                        </div>
                      </div>{' '}
                      <div className="col-6 col-md-3 flex p-2 md:flex-col">
                        <div className=" rounded-md bg-crio-neutral-200 p-4 text-left md:mr-3 md:bg-transparent md:p-0">
                          <div className="mb-4 mr-4 md:mr-0 ">
                            {/* <Image
                              filename="home/WhyCrio/Two.png"
                              className={styles.whyCrioImage}
                            ></Image> */}
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/WhyCrio/Two.png"
                              placeholder="blurred"
                              alt="Why Crio - Real Work Experience"
                              className={styles.whyCrioImage}
                            />
                          </div>
                          <h5
                            className={`font-semibold ${styles.customFont} mb-3 text-crio-green-900`}
                          >
                            True, Project-based Learning
                          </h5>

                          <p className="text-sm leading-loose">
                            Work on projects and activities inspired by the
                            industry to learn new skills.
                          </p>
                        </div>
                      </div>{' '}
                      <div className="col-6 col-md-3 flex p-2 md:flex-col">
                        <div className=" mb-8  rounded-md bg-crio-neutral-200 p-4 text-left md:mr-3 md:bg-transparent md:p-0">
                          <div className="mb-4 mr-4 md:mr-0 ">
                            {/* <Image
                              filename="home/WhyCrio/Four.png"
                              className={styles.whyCrioImage}
                            ></Image> */}
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/WhyCrio/Four.png"
                              placeholder="blurred"
                              alt="Why Crio - Attractive Scholarships"
                              className={styles.whyCrioImage}
                            />
                          </div>
                          <h5
                            className={`font-semibold ${styles.customFont} mb-3 text-crio-green-900`}
                          >
                            Attractive Scholarships
                          </h5>

                          <p className="text-sm leading-loose">
                            Participate in the free trial and earn scholarships
                            to our Signature Programs.
                          </p>
                        </div>
                      </div>{' '}
                      <div className="col-6 col-md-3 flex p-2 md:flex-col">
                        <div className=" mb-8  rounded-md bg-crio-neutral-200 p-4 text-left md:mr-3 md:bg-transparent md:p-0">
                          <div className="mb-4 mr-4 md:mr-0 ">
                            {/* <Image
                              filename="home/WhyCrio/Three.png"
                              className={styles.whyCrioImage}
                            ></Image> */}
                            <StaticImage
                              loading="lazy"
                              src="../../images/home/WhyCrio/Three.png"
                              placeholder="blurred"
                              alt="Why Crio - Badges & Certificates"
                              className={styles.whyCrioImage}
                            />
                          </div>
                          <h5
                            className={`font-semibold ${styles.customFont} mb-3 text-crio-green-900`}
                          >
                            Badges & Certificates
                          </h5>

                          <p className="text-sm leading-loose">
                            Showcase your new skills from the free trial with
                            shareable LinkedIn badges.
                          </p>
                        </div>
                      </div>{' '}
                    </div>
                    <div className={`flex justify-center  ${styles.button}`}>
                      <ApplyNowCTA
                        innerText="Book Now"
                        program={pageData}
                        type={typeOfProgram}
                        meId={typeOfProgram}
                        link={typeform}
                        location="PROGRAM_DETAIL_HIGHLIGHTS"
                        text="Book your Free Trial"
                        width={'250px'}
                        className="shimmer-btn bg-crio-yellow-500 py-3  text-crio-green-900 "
                        applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                        openEvent="FREE_ME_START_NOW_CLICKED"
                        closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                      ></ApplyNowCTA>
                    </div>{' '}
                    <div className="mt-3 flex justify-center">
                      <p className={`text-crio-green-900 ${styles.customFont}`}>
                        Earn scholarships after trial
                      </p>
                    </div>
                  </div>
                </FadeInWhenVisible>
              </div>
            </div>
          </section>
          {getSaleImageCaptionFlex(sale)}
          <section className="bg-crio-neutral-100">
            <div className="py-16">
              <span
                id={EAnchorIds.ESSENTIAL_DEVELOPER_SKILLS}
                className="anchor"
              ></span>
              <div className="container text-center  text-crio-neutral-800">
                <FadeInWhenVisible
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <h2
                    className={`mb-8 text-crio-green-700 ${styles.heading} text-left md:text-center`}
                  >
                    Learn the essentials to be the new-age web developer
                  </h2>
                </FadeInWhenVisible>
                <FadeInWhenVisible
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <div className="container mb-10">
                    {pageData.offeringDetails.map((element, idx) => {
                      const image = getImage(element.image.localFile);

                      return (
                        <div
                          key={idx}
                          id={element?.anchorId}
                          style={{ scrollMarginTop: '220px' }}
                        >
                          <AccordionV2
                            open={true}
                            className="mb-6 p-6"
                            type={element.type}
                            image={image}
                            title={element.title}
                            // duration={`${element.durationHours} Hours`}
                            description={element.description}
                            skills={element.skills}
                            takeaways={element.takeaways}
                            bgColor={'bg-crio-neutral-75'}
                          ></AccordionV2>
                        </div>
                      );
                    })}

                    {/* {pageData.offeringDetails.map((element, idx) => (
                      <AccordionV2
                        open={true}
                        className="mb-6 p-6"
                        type={element.type}
                        // image={element.image.url.split('/')[2]}
                        title={element.title}
                        description={element.description}
                        // skills={element.skills}
                        // takeaways={element.takeaways}
                        bgColor={'bg-crio-neutral-75'}
                      ></AccordionV2>
                    ))} */}
                  </div>
                </FadeInWhenVisible>
                <div className={`flex justify-center  ${styles.button}`}>
                  <ApplyNowCTA
                    innerText="Book Now"
                    program={pageData}
                    type={typeOfProgram}
                    meId={typeOfProgram}
                    link={typeform}
                    location="PROGRAM_DETAIL_CARD"
                    text="Book Your Free Trial"
                    width={'250px'}
                    className="shimmer-btn mx-auto bg-crio-yellow-500  py-3 text-crio-green-900"
                    applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                    openEvent="FREE_ME_START_NOW_CLICKED"
                    closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                  ></ApplyNowCTA>
                </div>
                <p className="my-2 text-crio-green-900">
                  Earn scholarships after trial
                </p>
              </div>
            </div>
          </section>
          <section className="bg-crio-neutral-75 md:pt-0">
            <div className="relative pt-16 pb-16">
              <div className="container text-center text-crio-green-700">
                <h2 className={`mb-14 ${styles.heading}`}>
                  Crio Grads have Cracked their Dream Careers In
                </h2>
              </div>
              <div className="mb-10">
                <div className="hidden md:block">
                  <div className="mb-4">
                    <CarouselV2
                      data={graduatesData.slice(0, graduatesData.length / 2)}
                    />
                  </div>
                  <div>
                    <CarouselV2
                      data={graduatesData.slice(graduatesData.length / 2)}
                    />
                  </div>
                </div>
                <div className=" md:hidden">
                  <div className="mb-4">
                    <CarouselV2
                      isMobile={true}
                      data={graduatesData.slice(0, graduatesData.length / 2)}
                    />
                  </div>
                  <div>
                    <CarouselV2
                      isMobile={true}
                      data={graduatesData.slice(graduatesData.length / 2)}
                    />
                  </div>
                </div>
              </div>
              <div className={`flex justify-center  ${styles.button}`}>
                <ApplyNowCTA
                  innerText="Book Now"
                  program={pageData}
                  type={typeOfProgram}
                  meId={typeOfProgram}
                  link={typeform}
                  location="PROGRAM_DETAIL_DEVELOPERS_CRACKED"
                  text="Book Your Free Trial"
                  width={'250px'}
                  className="shimmer-btn bg-crio-yellow-500 py-3  text-crio-green-900 "
                  applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                  openEvent="FREE_ME_START_NOW_CLICKED"
                  closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                ></ApplyNowCTA>
              </div>{' '}
              <div className="mt-3 flex justify-center">
                <p className={`text-crio-green-900 ${styles.customFont}`}>
                  Earn scholarships after trial
                </p>
              </div>
            </div>
          </section>
          <section className="bg-crio-green-700">
            <div className="pt-10 pb-10">
              <div className=" text-center  text-white ">
                <FadeInWhenVisible
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  {' '}
                  <div className="container  ">
                    <h2 className={`mb-4 ${styles.heading} relative`}>
                      Join 1000+ ambitious developers{' '}
                      <div className={styles.sketchParent}>
                        <div ref={ref} id="sketch">
                          {' '}
                          <StaticImage
                            loading="lazy"
                            src="../../images/fellowship/sketch.png"
                            placeholder="blurred"
                            alt="Sketch"
                            className={styles.sketch}
                          />
                          {/* <Image
                            filename="fellowship/sketch.png"
                            className={styles.sketch}
                          ></Image>{' '} */}
                        </div>
                      </div>
                      who've <div className="hidden md:block"></div>
                      taken their career to new heights with Crio
                    </h2>
                  </div>
                  <div className="max-w-11/12 mx-6 md:mx-auto md:max-w-[1000px]">
                    <DownloadPlacementStats>
                      Get more insights on how Crio can help you crack a full
                      stack or backend role in top tech companies.
                    </DownloadPlacementStats>
                  </div>
                </FadeInWhenVisible>
                <div className="mb-12">
                  <div className="hidden md:block">
                    <FellowshipCarousel
                      data={testimonialQuotesData}
                      isMobile={false}
                    ></FellowshipCarousel>
                  </div>{' '}
                  <div className="block md:hidden">
                    <FellowshipCarousel
                      data={testimonialQuotesData}
                      isMobile={true}
                    ></FellowshipCarousel>
                  </div>
                </div>
                <div className={`flex justify-center  ${styles.button}`}>
                  <ApplyNowCTA
                    innerText="Book Now"
                    program={pageData}
                    type={typeOfProgram}
                    meId={typeOfProgram}
                    link={typeform}
                    location="PROGRAM_DETAIL_TESTIMONIALS"
                    text="Book Your Free Trial"
                    width={'250px'}
                    className="shimmer-btn mx-auto bg-crio-yellow-500  py-3 text-crio-green-900"
                    applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                    openEvent="FREE_ME_START_NOW_CLICKED"
                    closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                  ></ApplyNowCTA>
                </div>
                <p className="my-2 text-crio-neutral-100">
                  Earn scholarships after trial
                </p>
              </div>
            </div>
          </section>
          <section className="bg-crio-green-600 text-white">
            <div className=" py-10 ">
              <div className="container">
                <div className="row flex items-center">
                  <div className="col-12 col-md-4 flex justify-center ">
                    {' '}
                    <div className="mb-8 w-4/12 md:mb-0 md:w-6/12">
                      <StaticImage
                        loading="lazy"
                        src="../../images/CrioAdvisor.png"
                        placeholder="blurred"
                        alt="Crio Advisor"
                      />
                      {/* <Image filename={'CrioAdvisor.png'}></Image> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-8 text-center md:text-left">
                    <h2
                      className={`${styles.customFont}  mb-8 font-normal md:mb-4`}
                    >
                      Have any further questions?
                    </h2>
                    <div
                      className={` flex justify-center md:justify-start ${styles.button}`}
                    >
                      <Button
                        background="#FFC372"
                        color="#05343A"
                        type="primary"
                        className="font-bold"
                        onClick={(e) => {
                          window && window.openChatBot && window.openChatBot();
                        }}
                      >
                        Chat with us
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-crio-neutral-75">
            <div className="pt-10 pb-10  md:pt-10">
              <div className="container text-center  text-crio-neutral-800 ">
                <div className="row mb-16">
                  <div
                    className={`col-12 col-md-6 flex text-left md:justify-end ${styles.customFont}`}
                  >
                    <div className="md:w-10/12">
                      <div className="mb-3 flex items-center md:block">
                        <h4 className="hidden text-crio-green-600 md:block">
                          Real
                        </h4>
                        <h2 className="flex text-crio-green-700 md:block">
                          <span className="mr-2 block md:mr-0 md:hidden">
                            Real{' '}
                          </span>{' '}
                          Work experience
                        </h2>
                      </div>
                      <div className="mb-3 flex items-center md:block">
                        <h4 className="hidden text-crio-green-600 md:block">
                          Real
                        </h4>
                        <h2 className="flex text-crio-green-700 md:block">
                          <span className="mr-2 block md:mr-0 md:hidden">
                            Real Project-based learning
                          </span>{' '}
                        </h2>
                        <h2 className="hidden text-crio-green-700 md:block">
                          Project-based learning
                        </h2>
                      </div>{' '}
                      <div className="mb-3 flex items-center md:block">
                        <h4 className="hidden text-crio-green-600 md:block">
                          Real
                        </h4>
                        <h2 className="flex text-crio-green-700 md:block">
                          <span className="mr-2 block md:mr-0 md:hidden">
                            Real{' '}
                          </span>{' '}
                          Skill development
                        </h2>
                      </div>
                      <div>
                        {' '}
                        <h5 className="mb-6 font-semibold text-crio-green-600 md:mb-0">
                          With Crio
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 text-left leading-loose ">
                    <div className="md:w-10/12">
                      <p>
                        Existing ways of learning through video-based courses
                        and expensive bootcamps aren't as effective when it
                        comes to solving real-world tech problems.
                      </p>{' '}
                      <br></br>
                      <p>
                        Recruiters too, look for real project experience when
                        hiring tech professionals and here at Crio, we strive to
                        provide just that. We empower learners with high-quality
                        applied learning opportunities and build skills that
                        translate into career growth and success.
                      </p>
                    </div>
                  </div>
                </div>
                <FadeInWhenVisible
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <div className="mb-10">
                    <div className="mx-auto hidden w-10/12 md:block">
                      <StaticImage
                        loading="lazy"
                        src="../../images/fellowship/Desktop_Table.png"
                        placeholder="blurred"
                        alt="Crio Comparision"
                      />
                      {/* <Image filename="fellowship/Desktop_Table.png"></Image> */}
                    </div>
                    <div className=" mx-auto  md:hidden">
                      <StaticImage
                        loading="lazy"
                        src="../../images/fellowship/Mobile_Table.png"
                        placeholder="blurred"
                        alt="Crio Comparision"
                      />
                      {/* <Image filename="fellowship/Mobile_Table.png"></Image> */}
                    </div>
                  </div>
                </FadeInWhenVisible>
                <FadeInWhenVisible
                  variants={{
                    visible: { opacity: 1, y: 0 },
                    hidden: { opacity: 0, y: 100 },
                  }}
                >
                  <div className="mb-16 text-white">
                    <div className="flex flex-wrap justify-center">
                      <div className="m-4 h-full text-left font-light md:w-5/12 ">
                        <div className="rounded-t-sm bg-crio-green-600 p-5 pb-8 leading-loose md:pb-24">
                          <p>
                            Their cutting edge platform and pedagogical methods
                            are a class apart and help groom engineers not only
                            on the technical skills but also the tools necessary
                            for today's software development industry
                          </p>
                        </div>
                        <div className="relative rounded-b-sm bg-crio-green-900 p-5 text-center font-normal leading-relaxed">
                          <div
                            className="inset-center absolute top-0"
                            //   id={styles.experienceTestimonialImage}
                          >
                            {' '}
                            <StaticImage
                              loading="lazy"
                              src="../../images/fellowship/IndustryTestimonial/Piyush.png"
                              placeholder="blurred"
                              alt="Industry Testimonial"
                              className={styles.paymentImage}
                            />
                            {/* <Image
                              filename="fellowship/IndustryTestimonial/Piyush.png"
                              className={styles.paymentImage}
                            ></Image> */}
                          </div>
                          <div className="">
                            <div className="mt-5">
                              <h6
                                className={`${styles.customFont} font-semibold`}
                              >
                                Piyush Goel
                              </h6>
                              <p className="text-crio-green-200">
                                VP - Engineering
                              </p>
                              <div className="">
                                <StaticImage
                                  loading="lazy"
                                  src="../../images/fellowship/IndustryTestimonial/Capillary.png"
                                  placeholder="blurred"
                                  alt="Industry Testimonial"
                                  className={`${styles.testimonialOwner} mx-auto`}
                                />
                                {/* <Image
                                  filename="fellowship/IndustryTestimonial/Capillary.png"
                                  className={`${styles.testimonialOwner} mx-auto`}
                                ></Image> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="m-4 h-full text-left font-light md:w-5/12">
                        <div className="rounded-t-sm bg-crio-green-600 p-5 pb-8 leading-loose ">
                          <p>
                            Crio trains developers to have a hunger for
                            self-learning along with good knowledge of tools and
                            methodologies used in day-to-day development, which
                            are THE MOST important qualities for fast-paced and
                            value-driven companies like Jumbotail. Looking
                            forward to hiring more engineers from Crio.
                          </p>
                        </div>
                        <div className="relative rounded-b-sm bg-crio-green-900 p-5 text-center font-normal leading-relaxed">
                          <div
                            className="inset-center absolute top-0"
                            //   id={styles.experienceTestimonialImage}
                          >
                            {' '}
                            <StaticImage
                              loading="lazy"
                              src="../../images/fellowship/IndustryTestimonial/Ramesh.png"
                              placeholder="blurred"
                              alt="Industry Testimonial"
                              className={styles.paymentImage}
                            />
                            {/* <Image
                              filename="fellowship/IndustryTestimonial/Ramesh.png"
                              className={styles.paymentImage}
                            ></Image> */}
                          </div>
                          <div className="">
                            <div className="mt-5">
                              <h6
                                className={`${styles.customFont} font-semibold`}
                              >
                                Ramesh Kumar Katreddi
                              </h6>
                              <p className="text-crio-green-200">
                                VP - Engineering
                              </p>
                              <div className="">
                                {/* <Image
                                  filename="fellowship/IndustryTestimonial/Jumbotail.png"
                                  className={`${styles.testimonialOwner} mx-auto`}
                                ></Image> */}
                                <StaticImage
                                  loading="lazy"
                                  src="../../images/fellowship/IndustryTestimonial/Jumbotail.png"
                                  placeholder="blurred"
                                  alt="Industry Testimonial"
                                  className={`${styles.testimonialOwner} mx-auto`}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FadeInWhenVisible>
                <div className={`flex justify-center  ${styles.button}`}>
                  <ApplyNowCTA
                    innerText="Book Now"
                    program={pageData}
                    type={typeOfProgram}
                    meId={typeOfProgram}
                    link={typeform}
                    location="PROGRAM_DETAIL_CRIO_COMPARISION"
                    text="Book Your Free Trial"
                    width={'250px'}
                    className="shimmer-btn mx-auto bg-crio-yellow-500  py-3 text-crio-green-900"
                    applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                    openEvent="FREE_ME_START_NOW_CLICKED"
                    closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                  ></ApplyNowCTA>
                </div>
                <p className="my-2 text-crio-green-900">
                  Earn scholarships after trial
                </p>
              </div>
            </div>
          </section>
          <section className="faqs-section bg-crio-neutral-100">
            <div className="container pt-10 text-center text-crio-green-700">
              <h2 className={`mb-10 ${styles.heading} `}>FAQs</h2>
              <div className="mb-20 text-crio-neutral-800">
                <FAQsV2 faqsData={pageData.faq} />
              </div>
            </div>
          </section>
          <section className="bg-crio-neutral-100 text-white">
            <div className="pt-8 pb-16  md:pt-16 ">
              <div className="container">
                <div className="row ">
                  <div className="col-12 col-md-4 order-2 mt-6 flex justify-center md:order-1 md:mt-0">
                    {' '}
                    <div className="w-9/12 ">
                      <GatsbyImage
                        loading="lazy"
                        image={FooterImage}
                        alt={'Footer Image'}
                      />
                      {/* <Image
                        filename={`strapi/${
                          pageData.FooterImage.url.split('/')[2]
                        }`}
                      ></Image> */}
                    </div>
                  </div>
                  <div className="col-12 col-md-8 order-1 text-center md:order-2 md:text-left">
                    <h2
                      className={`${styles.customFont}  mb-8 text-crio-green-700 md:mb-4`}
                    >
                      Experience <div className="block md:hidden"></div>{' '}
                      #LearnByDoing<div className="hidden md:block"></div> for
                      <div className="block md:hidden"></div> Free!
                    </h2>
                    <div>
                      <div
                        className={` flex justify-center md:justify-start ${styles.button}`}
                      >
                        <ApplyNowCTA
                          innerText="Book Now"
                          program={pageData}
                          type={typeOfProgram}
                          meId={typeOfProgram}
                          link={typeform}
                          location="PROGRAM_DETAIL_FOOTER"
                          text="Book Your Free Trial"
                          width={'250px'}
                          className="shimmer-btn bg-crio-yellow-500 py-3  text-crio-green-900"
                          applyNowInsideClassName="shimmer-btn bg-crio-yellow-500 text-crio-green-900  py-3"
                          openEvent="FREE_ME_START_NOW_CLICKED"
                          closeEvent="FREE_ME_EMAIL_FORM_CLOSED"
                        ></ApplyNowCTA>
                      </div>
                      <p className="my-2 px-2 text-crio-green-900">
                        Earn scholarships after trial
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <FooterV2 />
          {/* <Footer></Footer> */}
        </section>
      </>
    );
  }
}

function FadeInWhenVisible(props) {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: props.duration ? props.duration : 0.8 }}
      {...props}
      //   exit="hidden"
    >
      {props.children}
    </motion.div>
  );
}

export const pageQuery = graphql`
  query {
    strapiPrograms(PrimaryProgramID: { eq: "FREE_TRIAL" }) {
      ...strapiProgramsFragment
    }

    ...strapiDataFragment
  }
`;
